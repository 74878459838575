import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled from 'styled-components';
import { Heading } from 'forms/SectionHeading/styles';

export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: right;

  margin: ${spacing(3)} 0 ${spacing(7)};
`;

export const EditButtonLink = styled(ButtonLink)`
  && {
    ${fonts.paragraph}

    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge}
    `}
  }

  margin-left: auto;
`;

export const EditButtonIcon = styled(Icon)`
  margin-right: ${spacing(1)};
`;

export const HeadingSpan = styled.span`
  ${fonts.paragraph};

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge};
  `}
`;

export const EditSpan = styled.span`
  padding-top: ${spacing(1)};
  ${mediaQuery.tabletPortrait`
    padding-top: ${spacing(1.5)};
  `}
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${spacing(7)} 0 0;
  border-bottom: 1px solid ${colors.neutral03};
  padding-bottom: ${spacing(5)};

  ${mediaQuery.tabletPortrait`
    padding-bottom: ${spacing(4)};
  `}
`;

export const DetailHeading = styled(Heading)`
  font-weight: ${heavyFontWeight};
  margin: 0;
`;
