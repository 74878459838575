import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { GridItemWrapper } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { StyledRadioInput } from 'components/BooleanRadioInput/styles';
import Declaration from 'components/Declaration';
import { QuestionFieldWithMargin } from 'components/Declaration/styles';
import RichTextWithModal from 'components/RichTextWithModal';
import SectionHeading from 'forms/SectionHeading';

export const DeclarationSectionContainer = styled.div`
  padding-bottom: ${spacing(5)};

  ${mediaQuery.tabletPortrait`
    padding-bottom: 0;
  `}

  ${mediaQuery.tabletLandscape`
    padding-bottom: ${spacing(4)};
  `}
`;

export const StyledGridItem = styled(GridItem)`
  padding: ${spacing(2)};
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(4)}
  `}
`;

export const StyledPanel = styled(SimplePanel)`
  && {
    border: none;
    background-color: ${colors.core06};
    margin-top: ${spacing(4)};
  }
`;

export const StyledSectionHeading = styled(SectionHeading)`
  & {
    margin-top: ${spacing(6)};
    margin-bottom: ${spacing(1)};
    ${mediaQuery.tabletLandscape`
      margin-bottom: ${spacing(2)};
    `}
  }
`;

export const RichTextWithModalAndMarginBottom = styled(RichTextWithModal)`
  margin-bottom: 0;
  ${mediaQuery.tabletLandscape`
      margin-bottom: ${spacing(1)};
    `}
`;

export const DeclarationWithBottomMargin = styled(Declaration)`
  ${mediaQuery.tabletPortrait`
    margin-top: 0;
    padding: ${spacing(4)} ${spacing(4)} ${spacing(3)};
  `};

  > ${Grid} > ${GridItemWrapper} {
    padding: 0;
    margin-bottom: ${spacing(3)};

    ${mediaQuery.tabletPortrait`
        margin-bottom: ${spacing(4)};
    `};
  }

  && {
    p + p,
    p + ul,
    ul + p {
      margin-top: ${spacing(3)};
    }

    p + p.small {
      margin-top: ${spacing(2)};
    }
  }

  ${QuestionFieldWithMargin} {
    margin-bottom: 0;
    background-color: ${colors.neutral08};

    border: ${({ error, theme }) =>
      error ? '' : `1px solid ${colors.neutral02({ theme })}`};

    ${StyledRadioInput} {
      display: flex;
      flex-direction: column;
      gap: ${spacing(2)};

      ${mediaQuery.tabletPortrait`
        flex-direction: row;
        gap: ${spacing(4)};
      `};

      ${RadioItem} {
        margin-left: 0;
      }
    }
  }
`;
