import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const ContactDetailsSectionContainer = styled(SimplePanel)`
  padding: ${spacing(3)} ${spacing(2)} 0;
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(5)} ${spacing(4)} ${spacing(1.125)};
  `}
  #divider {
    margin: 0;
  }
`;

export const StyledFlexibleWidthDivider = styled(FlexibleWidthDivider)`
  margin: ${spacing(3)} 0 ${spacing(4)};
  ${mediaQuery.tabletLandscape`
    margin: ${spacing(4)} 0 ${spacing(3)};
  `}
`;
